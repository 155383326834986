import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {NotificationActions} from './euroports.actions';
import {InAppNotificationsApiService} from "../../api/services/in-app-notifications-api.service";

export type NotificationStateModel = {
  count?: number
}

@State<NotificationStateModel>({
  name: 'count',
  defaults: {
    count: 0
  }
})
@Injectable()
export class NotificationState {

  constructor(private inAppNotificationsApiService: InAppNotificationsApiService) {
  }

  @Selector()
  static count(state: NotificationStateModel) {
    return state.count;
  }

  @Action(NotificationActions.Load)
  loadNotificationsCount(ctx: StateContext<NotificationStateModel>) {
    this.inAppNotificationsApiService.getNotificationsCount()
      .subscribe(count => {
        ctx.setState({ count });
      });
  }
}
